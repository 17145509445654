import { readonly } from "vue"

export default {
  async findById(subscriptionId: number): Promise<Subscription | null> {
    return await apiGet<Subscription>(`billing/subscription/${subscriptionId}`).catch((error) => {
      if (error.response?.status == 404) {
        return null
      } else {
        throw error
      }
    })
  }
}

export interface Subscription {
  id: number,
  status: string,
  planId: string,
  recurrence: Interval,
  shippingCost: number,
  startAt: string,
  endAt?: string,
  migratedFrom?: number,
  nextBillingAt?: string,
  paymentProfileType?: string
}

export interface PrimarySubscription {
  id: number,
  status: string,
  planId: string,
  recurrence: Interval,
  shippingAddressId: number,
  shippingCost: number,
  pendingBankSlipUrl?: string,
  startAt: string,
  endAt?: string,
  migratedFrom?: number,
  nextBillingAt?: string,
  nextRenewalAt?: string,
  paymentProfileId: string,
  paymentProfileType: string
}

export interface Interval {
  period: Period,
  numberOfPeriods: number
}

export enum Period {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

const devPlans: Record<string, string> = {
  "monthly": "br.com.magenta.vindi.test.basic.monthly.16990",
  "quarterly": "br.com.magenta.vindi.test.basic.quarterly.46770",
  "biannually": "br.com.magenta.vindi.test.basic.biannually.95940",
  "annually": "br.com.magenta.vindi.test.basic.annually.179880",

  "monthly-with-discount": "br.com.magenta.vindi.test.basic.monthly.16990",
  "quarterly-with-discount": "br.com.magenta.vindi.test.basic.quarterly.46770.fallback.monthly.15590",
  "biannually-with-discount": "br.com.magenta.vindi.test.basic.biannually.95940.fallback.monthly.15990",
  "annually-with-discount": "br.com.magenta.vindi.test.basic.annually.179880.fallback.monthly.14990",
}

const prodPlans: Record<string, string> = {
  "monthly": "br.com.magenta.vindi.basic.monthly.16990",
  "quarterly": "br.com.magenta.vindi.basic.quarterly.46770",
  "biannually": "br.com.magenta.vindi.basic.biannually.95940",
  "annually": "br.com.magenta.vindi.basic.annually.179880",


  "monthly-with-discount": "br.com.magenta.vindi.basic.monthly.16990",
  "quarterly-with-discount": "br.com.magenta.vindi.basic.quarterly.46770.fallback.monthly.15590",
  "biannually-with-discount": "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990",
  "annually-with-discount": "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990",
}

export function planAliasToId(planAlias: string, withDiscount: boolean = false): string {
  const { env } = useConfig()

  const completePlanAlias = `${planAlias}${withDiscount ? '-with-discount' : ''}`

  if (env == 'development') {
    return devPlans[completePlanAlias]
  } else {
    return prodPlans[completePlanAlias]
  }
}

export const planName: Record<string, string> = readonly({
  "br.com.magenta.vindi.test.basic.monthly.11990": "Mensal",
  "br.com.magenta.vindi.test.basic.quarterly.34470": "Trimestral",
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.34470": "Trimestral",
  "br.com.magenta.vindi.test.basic.biannually.65940": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.no-installments.65940": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.65940.fallback.quarterly.32970": "Semestral",
  "br.com.magenta.vindi.test.basic.annually.119880": "Anual",
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.biannually.59940": "Anual",
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.quarterly.29970": "Anual",
  "br.com.magenta.vindi.test.basic.monthly.15990": "Mensal",
  "br.com.magenta.vindi.test.basic.quarterly.46770": "Trimestral",
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.46770": "Trimestral",
  "br.com.magenta.vindi.test.basic.quarterly.46770.fallback.monthly.15590": "Trimestral",
  "br.com.magenta.vindi.test.basic.biannually.87540": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.no-installments.87540": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.87540.fallback.monthly.14590": "Semestral",
  "br.com.magenta.vindi.test.basic.annually.163080": "Anual",
  "br.com.magenta.vindi.test.basic.annually.163080.fallback.monthly.13590": "Anual",
  "br.com.magenta.vindi.test.basic.biannually.89940": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.89940.fallback.monthly.14990": "Semestral",
  "br.com.magenta.vindi.test.basic.annually.167880": "Anual",
  "br.com.magenta.vindi.test.basic.annually.167880.fallback.monthly.13990": "Anual",
  "br.com.magenta.vindi.test.basic.monthly.16990": "Mensal",
  "br.com.magenta.vindi.test.basic.biannually.95940": "Semestral",
  "br.com.magenta.vindi.test.basic.biannually.95940.fallback.monthly.15990": "Semestral",
  "br.com.magenta.vindi.test.basic.annually.179880": "Anual",
  "br.com.magenta.vindi.test.basic.annually.179880.fallback.monthly.14990": "Anual",

  "br.com.magenta.vindi.basic.monthly.11990": "Mensal",
  "br.com.magenta.vindi.basic.quarterly.34470": "Trimestral",
  "br.com.magenta.vindi.basic.quarterly.no-installments.34470": "Trimestral",
  "br.com.magenta.vindi.basic.biannually.65940": "Semestral",
  "br.com.magenta.vindi.basic.biannually.no-installments.65940": "Semestral",
  "br.com.magenta.vindi.basic.biannually.65940.fallback.quarterly.32970": "Semestral",
  "br.com.magenta.vindi.basic.annually.119880": "Anual",
  "br.com.magenta.vindi.basic.annually.119880.fallback.biannually.59940": "Anual",
  "br.com.magenta.vindi.basic.annually.119880.fallback.quarterly.29970": "Anual",
  "br.com.magenta.vindi.basic.monthly.15990": "Mensal",
  "br.com.magenta.vindi.basic.quarterly.46770": "Trimestral",
  "br.com.magenta.vindi.basic.quarterly.no-installments.46770": "Trimestral",
  "br.com.magenta.vindi.basic.quarterly.46770.fallback.monthly.15590": "Trimestral",
  "br.com.magenta.vindi.basic.biannually.87540": "Semestral",
  "br.com.magenta.vindi.basic.biannually.no-installments.87540": "Semestral",
  "br.com.magenta.vindi.basic.biannually.87540.fallback.monthly.14590": "Semestral",
  "br.com.magenta.vindi.basic.annually.163080": "Anual",
  "br.com.magenta.vindi.basic.annually.163080.fallback.monthly.13590": "Anual",
  "br.com.magenta.vindi.basic.biannually.89940": "Semestral",
  "br.com.magenta.vindi.basic.biannually.89940.fallback.monthly.14990": "Semestral",
  "br.com.magenta.vindi.basic.annually.167880": "Anual",
  "br.com.magenta.vindi.basic.annually.167880.fallback.monthly.13990": "Anual",
  "br.com.magenta.vindi.basic.monthly.16990": "Mensal",
  "br.com.magenta.vindi.basic.biannually.95940": "Semestral",
  "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990": "Semestral",
  "br.com.magenta.vindi.basic.annually.179880": "Anual",
  "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990": "Anual",
});

export const planBasePrice: Record<string, number> = readonly({
  "br.com.magenta.vindi.test.basic.monthly.11990": 11990,
  "br.com.magenta.vindi.test.basic.quarterly.34470": 11490,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.34470": 11490,
  "br.com.magenta.vindi.test.basic.biannually.65940": 10990,
  "br.com.magenta.vindi.test.basic.biannually.no-installments.65940": 10990,
  "br.com.magenta.vindi.test.basic.biannually.65940.fallback.quarterly.32970": 10990,
  "br.com.magenta.vindi.test.basic.annually.119880": 9990,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.biannually.59940": 9990,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.quarterly.29970": 9990,
  "br.com.magenta.vindi.test.basic.monthly.15990": 15990,
  "br.com.magenta.vindi.test.basic.quarterly.46770": 15590,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.46770": 15590,
  "br.com.magenta.vindi.test.basic.quarterly.46770.fallback.monthly.15590": 15590,
  "br.com.magenta.vindi.test.basic.biannually.87540": 14590,
  "br.com.magenta.vindi.test.basic.biannually.no-installments.87540": 14590,
  "br.com.magenta.vindi.test.basic.biannually.87540.fallback.monthly.14590": 14590,
  "br.com.magenta.vindi.test.basic.annually.163080": 13590,
  "br.com.magenta.vindi.test.basic.annually.163080.fallback.monthly.13590": 13590,
  "br.com.magenta.vindi.test.basic.biannually.89940": 14990,
  "br.com.magenta.vindi.test.basic.biannually.89940.fallback.monthly.14990": 14990,
  "br.com.magenta.vindi.test.basic.annually.167880": 13990,
  "br.com.magenta.vindi.test.basic.annually.167880.fallback.monthly.13990": 13990,
  "br.com.magenta.vindi.test.basic.monthly.16990": 16990,
  "br.com.magenta.vindi.test.basic.biannually.95940": 15990,
  "br.com.magenta.vindi.test.basic.biannually.95940.fallback.monthly.15990": 15990,
  "br.com.magenta.vindi.test.basic.annually.179880": 14990,
  "br.com.magenta.vindi.test.basic.annually.179880.fallback.monthly.14990": 14990,

  "br.com.magenta.vindi.basic.monthly.11990": 11990,
  "br.com.magenta.vindi.basic.quarterly.34470": 11490,
  "br.com.magenta.vindi.basic.quarterly.no-installments.34470": 34470,
  "br.com.magenta.vindi.basic.biannually.65940": 10990,
  "br.com.magenta.vindi.basic.biannually.no-installments.65940": 65940,
  "br.com.magenta.vindi.basic.biannually.65940.fallback.quarterly.32970": 10990,
  "br.com.magenta.vindi.basic.annually.119880": 9990,
  "br.com.magenta.vindi.basic.annually.119880.fallback.biannually.59940": 9990,
  "br.com.magenta.vindi.basic.annually.119880.fallback.quarterly.29970": 9990,
  "br.com.magenta.vindi.basic.monthly.15990": 15990,
  "br.com.magenta.vindi.basic.quarterly.46770": 15590,
  "br.com.magenta.vindi.basic.quarterly.no-installments.46770": 15590,
  "br.com.magenta.vindi.basic.quarterly.46770.fallback.monthly.15590": 15590,
  "br.com.magenta.vindi.basic.biannually.87540": 14590,
  "br.com.magenta.vindi.basic.biannually.no-installments.87540": 14590,
  "br.com.magenta.vindi.basic.biannually.87540.fallback.monthly.14590": 14590,
  "br.com.magenta.vindi.basic.annually.163080": 13590,
  "br.com.magenta.vindi.basic.annually.163080.fallback.monthly.13590": 13590,
  "br.com.magenta.vindi.basic.biannually.89940": 14990,
  "br.com.magenta.vindi.basic.biannually.89940.fallback.monthly.14990": 14990,
  "br.com.magenta.vindi.basic.annually.167880": 13990,
  "br.com.magenta.vindi.basic.annually.167880.fallback.monthly.13990": 13990,
  "br.com.magenta.vindi.basic.monthly.16990": 16990,
  "br.com.magenta.vindi.basic.biannually.95940": 15990,
  "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990": 15990,
  "br.com.magenta.vindi.basic.annually.179880": 14990,
  "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990": 14990,
});

export const planInstallments: Record<string, number> = readonly({
  "br.com.magenta.vindi.test.basic.monthly.11990": 1,
  "br.com.magenta.vindi.test.basic.quarterly.34470": 3,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.34470": 1,
  "br.com.magenta.vindi.test.basic.biannually.65940": 6,
  "br.com.magenta.vindi.test.basic.biannually.no-installments.65940": 1,
  "br.com.magenta.vindi.test.basic.biannually.65940.fallback.quarterly.32970": 6,
  "br.com.magenta.vindi.test.basic.annually.119880": 12,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.biannually.59940": 12,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.quarterly.29970": 12,
  "br.com.magenta.vindi.test.basic.monthly.15990": 1,
  "br.com.magenta.vindi.test.basic.quarterly.46770": 3,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.46770": 1,
  "br.com.magenta.vindi.test.basic.quarterly.46770.fallback.monthly.15590": 3,
  "br.com.magenta.vindi.test.basic.biannually.87540": 6,
  "br.com.magenta.vindi.test.basic.biannually.87540.fallback.monthly.14590": 6,
  "br.com.magenta.vindi.test.basic.annually.163080": 12,
  "br.com.magenta.vindi.test.basic.annually.163080.fallback.monthly.13590": 12,
  "br.com.magenta.vindi.test.basic.biannually.89940": 6,
  "br.com.magenta.vindi.test.basic.biannually.89940.fallback.monthly.14990": 6,
  "br.com.magenta.vindi.test.basic.annually.167880": 12,
  "br.com.magenta.vindi.test.basic.annually.167880.fallback.monthly.13990": 12,
  "br.com.magenta.vindi.test.basic.monthly.16990": 1,
  "br.com.magenta.vindi.test.basic.biannually.95940": 6,
  "br.com.magenta.vindi.test.basic.biannually.95940.fallback.monthly.15990": 6,
  "br.com.magenta.vindi.test.basic.annually.179880": 12,
  "br.com.magenta.vindi.test.basic.annually.179880.fallback.monthly.14990": 12,

  "br.com.magenta.vindi.basic.monthly.11990": 1,
  "br.com.magenta.vindi.basic.quarterly.34470": 3,
  "br.com.magenta.vindi.basic.quarterly.no-installments.34470": 1,
  "br.com.magenta.vindi.basic.biannually.65940": 6,
  "br.com.magenta.vindi.basic.biannually.no-installments.65940": 1,
  "br.com.magenta.vindi.basic.biannually.65940.fallback.quarterly.32970": 6,
  "br.com.magenta.vindi.basic.annually.119880": 12,
  "br.com.magenta.vindi.basic.annually.119880.fallback.biannually.59940": 12,
  "br.com.magenta.vindi.basic.annually.119880.fallback.quarterly.29970": 12,
  "br.com.magenta.vindi.basic.monthly.15990": 1,
  "br.com.magenta.vindi.basic.quarterly.46770": 3,
  "br.com.magenta.vindi.basic.quarterly.no-installments.46770": 1,
  "br.com.magenta.vindi.basic.quarterly.46770.fallback.monthly.15590": 3,
  "br.com.magenta.vindi.basic.biannually.87540": 6,
  "br.com.magenta.vindi.basic.biannually.no-installments.87540": 1,
  "br.com.magenta.vindi.basic.biannually.87540.fallback.monthly.14590": 6,
  "br.com.magenta.vindi.basic.annually.163080": 12,
  "br.com.magenta.vindi.basic.annually.163080.fallback.monthly.13590": 12,
  "br.com.magenta.vindi.basic.biannually.89940": 6,
  "br.com.magenta.vindi.basic.biannually.89940.fallback.monthly.14990": 6,
  "br.com.magenta.vindi.basic.annually.167880": 12,
  "br.com.magenta.vindi.basic.annually.167880.fallback.monthly.13990": 12,
  "br.com.magenta.vindi.basic.monthly.16990": 1,
  "br.com.magenta.vindi.basic.biannually.95940": 6,
  "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990": 6,
  "br.com.magenta.vindi.basic.annually.179880": 12,
  "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990": 12,
});

export const planBillingParts: Record<string, number> = readonly({
  "br.com.magenta.vindi.test.basic.monthly.11990": 1,
  "br.com.magenta.vindi.test.basic.quarterly.34470": 3,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.34470": 1,
  "br.com.magenta.vindi.test.basic.biannually.65940": 6,
  "br.com.magenta.vindi.test.basic.biannually.no-installments.65940": 1,
  "br.com.magenta.vindi.test.basic.biannually.65940.fallback.quarterly.32970": 1,
  "br.com.magenta.vindi.test.basic.annually.119880": 12,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.biannually.59940": 1,
  "br.com.magenta.vindi.test.basic.annually.119880.fallback.quarterly.29970": 1,
  "br.com.magenta.vindi.test.basic.monthly.15990": 1,
  "br.com.magenta.vindi.test.basic.quarterly.46770": 3,
  "br.com.magenta.vindi.test.basic.quarterly.no-installments.46770": 1,
  "br.com.magenta.vindi.test.basic.quarterly.46770.fallback.monthly.15590": 1,
  "br.com.magenta.vindi.test.basic.biannually.87540": 6,
  "br.com.magenta.vindi.test.basic.biannually.87540.fallback.monthly.14590": 1,
  "br.com.magenta.vindi.test.basic.annually.163080": 12,
  "br.com.magenta.vindi.test.basic.annually.163080.fallback.monthly.13590": 1,
  "br.com.magenta.vindi.test.basic.biannually.89940": 6,
  "br.com.magenta.vindi.test.basic.biannually.89940.fallback.monthly.14990": 1,
  "br.com.magenta.vindi.test.basic.annually.167880": 12,
  "br.com.magenta.vindi.test.basic.annually.167880.fallback.monthly.13990": 1,
  "br.com.magenta.vindi.test.basic.monthly.16990": 1,
  "br.com.magenta.vindi.test.basic.biannually.95940": 6,
  "br.com.magenta.vindi.test.basic.biannually.95940.fallback.monthly.15990": 1,
  "br.com.magenta.vindi.test.basic.annually.179880": 12,
  "br.com.magenta.vindi.test.basic.annually.179880.fallback.monthly.14990": 1,

  "br.com.magenta.vindi.basic.monthly.11990": 1,
  "br.com.magenta.vindi.basic.quarterly.34470": 3,
  "br.com.magenta.vindi.basic.quarterly.no-installments.34470": 1,
  "br.com.magenta.vindi.basic.biannually.65940": 6,
  "br.com.magenta.vindi.basic.biannually.no-installments.65940": 1,
  "br.com.magenta.vindi.basic.biannually.65940.fallback.quarterly.32970": 1,
  "br.com.magenta.vindi.basic.annually.119880": 12,
  "br.com.magenta.vindi.basic.annually.119880.fallback.biannually.59940": 1,
  "br.com.magenta.vindi.basic.annually.119880.fallback.quarterly.29970": 1,
  "br.com.magenta.vindi.basic.monthly.15990": 1,
  "br.com.magenta.vindi.basic.quarterly.46770": 3,
  "br.com.magenta.vindi.basic.quarterly.no-installments.46770": 1,
  "br.com.magenta.vindi.basic.quarterly.46770.fallback.monthly.15590": 1,
  "br.com.magenta.vindi.basic.biannually.87540": 6,
  "br.com.magenta.vindi.basic.biannually.no-installments.87540": 1,
  "br.com.magenta.vindi.basic.biannually.87540.fallback.monthly.14590": 1,
  "br.com.magenta.vindi.basic.annually.163080": 12,
  "br.com.magenta.vindi.basic.annually.163080.fallback.monthly.13590": 1,
  "br.com.magenta.vindi.basic.biannually.89940": 6,
  "br.com.magenta.vindi.basic.biannually.89940.fallback.monthly.14990": 1,
  "br.com.magenta.vindi.basic.annually.167880": 12,
  "br.com.magenta.vindi.basic.annually.167880.fallback.monthly.13990": 1,
  "br.com.magenta.vindi.basic.monthly.16990": 1,
  "br.com.magenta.vindi.basic.biannually.95940": 6,
  "br.com.magenta.vindi.basic.biannually.95940.fallback.monthly.15990": 1,
  "br.com.magenta.vindi.basic.annually.179880": 12,
  "br.com.magenta.vindi.basic.annually.179880.fallback.monthly.14990": 1,
})
